<template>
  <el-container class="index">
    <el-aside class="left">
      <logo></logo>
      <navs @change="handleChange"></navs>
      <help></help>
    </el-aside>
    <el-main class="main">
      <router-view />
    </el-main>
  </el-container>
</template>
<script>
import { getList } from '@avue/avue-data/api/glob'
import navs from '@avue/avue-data/page/nav'
import logo from '@avue/avue-data/page/logo'
import help from '@avue/avue-data/page/help'
export default {
  name: "index",
  components: {
    navs,
    logo,
    help
  },
  data () {
    return {
      publicPath: process.env.VUE_APP_PATH,
    }
  },
  created () {
    this.initGlob();
  },
  mounted () {
    if (this.$website.isDemo) {
      this.$notify({
        dangerouslyUseHTMLString: true,
        title: 'iot-scada物联网组态系统上线啦',
        message: `点击预览`,
        duration: 0,
        onClick: () => {
          window.open('http://iot.huiteng.club/')
        }
      });
    }
  },
  methods: {
    initGlob () {
      getList({
        current: 1,
        size: 100,
      }).then(res => {
        let list = res.data.data.records
        list.forEach(ele => {
          window.$glob[ele.globalKey] = ele.globalValue
        })
      })
    },
    handleChange (item, index) {
      this.$router.push({ path: this.$website.routers.mainPath.replace(/\/+$/, '') + item.path })
    }
  }
}
</script>
<style lang="scss">
@import '@avue/avue-data/styles/list.scss';
.index {
  height: 100%;
  & > .left {
    position: relative;
    width: 220px !important;
    height: 100%;
    border-right: 1px solid #2d2d2d;
    background: #18181c;
  }
  & > .main {
    padding: 0;
  }
}
</style>